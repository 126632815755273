import {getConfigValue, requireConfigValues, getConfig} from '@elements/config-utils';
import {showNotification, clearAll} from '@elements/alert-notification';
import Modal from 'bootstrap/js/dist/modal';
import 'url-search-params-polyfill'; // Edge Polyfill
import 'whatwg-fetch'; // IE10 Polyfill
import fetch from '@elements/fetch';
import formDataEntries from 'form-data-entries';
import {find, findIn, findAll, on, removeAttribute, setAttribute, addClass, removeClass, closest, findAllIn} from '@elements/dom-utils';
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "@elements/data-set-utils";
const configName = '_cartConfig';
import asyncAppend from "@elements/async-append";

let $listLoading,
    $listResult,
    $cartResult,
    $cartNotifications,
    $count;

const defaultSelectors = {
    base: '.js-cart',
    form: '.js-cart__form',
    addButton: '.js-cart__add',
    removeButton: '.js-cart__remove',
    loading: '.js-cart__loading',
    result: '.js-cart__result',
    notifications: '.js-cart__notifications',
    count: '.js-cart-count',
    autoSubmitInputs: '.js-cart__autosubmit-change'
};

const defaultOptions = {
    formTrigger: 'submit',
    emptyCart: false,
    onAdd: () => {},
    onAddSucceed: () => {},
    onAddFailed: () => {}
};


export function init(options = defaultOptions, selectors = defaultSelectors){
    $listLoading = find('.js-cart__list-loading');
    $listResult = find('.js-cart__list-result');
    $count = findAll(selectors.count);
    $cartResult = find(selectors.result);
    $cartNotifications = find('.js-cart__notification');

    // initial cart list & cart list count
    if (($listResult && $listResult.length) || ($count && $count.length)) {
        getCartInfo();
    // } else if($count && $count.length) {
    //     getCartDropdown();
    }

    // initial cart count

    onFind(selectors.form, function (baseElement) {
        createCartForm(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });

    // remove product from cart
    onFind(selectors.removeButton, function (button) {
        let baseElement = closest(selectors.form, button) || $listResult;

        on('click', function (evt) {
            evt.preventDefault();

            let params;

            if (button.getAttribute('data-product-id')) {
                params = new URLSearchParams({
                    removeId: button.getAttribute('data-product-id')
                });
            }

            updateCart(getConfigValue('cartUrl', configName), params, options, baseElement);
        }, button)
    });
}

export function createCartForm(baseElement, options = defaultOptions, selectors = defaultSelectors) {
    options = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('cart', baseElement)
    };

    if (baseElement && baseElement.length) {
        let formTrigger = options.formTrigger;

        requireConfigValues(['cartUrl'], configName);

        on(formTrigger, function (evt) {
            evt.preventDefault();

            clearAll({
                $container: $cartNotifications
            });

            let formDataEntries = getFormDataEntries([baseElement]);
            let params = new URLSearchParams(formDataEntries);

            let url = baseElement.getAttribute('data-action') || getConfigValue('cartUrl', configName);
            updateCart(url, params, options, baseElement);

            call(options.onAdd);
        }, baseElement);

        if (baseElement.getAttribute('data-update-change')) {
            on('change', function () {
                let formDataEntries = getFormDataEntries([baseElement]);
                let params = new URLSearchParams(formDataEntries);

                let pendingRequest = fetch(baseElement.getAttribute('action'), {
                    body: params
                });

                asyncAppend({
                    target: findAllIn('.js-cart__form__result', baseElement),
                    loading: findAllIn('.js-cart__form__loading', baseElement),
                    notifications: findAllIn('.js-cart__form__notifications', baseElement)
                }, pendingRequest);

            }, baseElement);
        }
    }
}

function updateCart(url, params, options, baseElement) {
    if (closest('.modal', baseElement)) {
        let modalInstance = Modal.getInstance(closest('.modal', baseElement));
        modalInstance.hide();
    }

    let loadingElement = findIn('.js-cart__loading', baseElement) !== null ? findIn('.js-cart__loading', baseElement) : findIn('.js-cart__loading', $listResult);

    if ($cartResult) {
        loadingElement = findIn('.js-cart__loading', $cartResult);
    }

    if (loadingElement) {
        removeAttribute('hidden', loadingElement);
    }

    addClass('is-loading', baseElement);
    removeAttribute('hidden', $listLoading);

    let pendingRequest = fetch(url, {
        body: params
    });

    pendingRequest.then((result) => {
        return result.clone().json()
    }).then((result) => {
        if (result.success) {
            if (result.content && $cartResult) {
                $cartResult.innerHTML = result.content;
                initInScope($cartResult);
            }

            //update cart dropdown
            setListContent($listResult, $listLoading, result.dropdownContent);

            if (typeof result.count !== "undefined") {
                setCount($count, result.count);
            }

            addClass('is-finished', baseElement);

            let t = setTimeout(() => {
                removeClass('is-finished', baseElement);

                clearTimeout(t);
            }, 3000);


            call(options.onAddSucceed);
        }else{
            call(options.onAddFailed);
        }

        if (loadingElement) {
            setAttribute('hidden', true, loadingElement);
        }
        removeClass('is-loading', baseElement);

    }).catch((error) => {
        if (loadingElement) {
            setAttribute('hidden', true, loadingElement);
        }
        removeClass('is-loading', baseElement);

        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    });

    showNotification(pendingRequest, {
        $container: $cartNotifications
    });
}

// export function getCartDropdown() {
//     requireConfigValues(['infoUrl'], configName);
//
//     let request = fetch(getConfigValue('infoUrl', configName), {
//         method: 'get',
//     });
//
//     removeAttribute('hidden', $listLoading);
//
//     showNotification(request);
//
//     request.then(response => response.json())
//         .then(result => {
//             if (result.success) {
//                 setListContent($listResult, $listLoading, result.html);
                //
                // if (typeof result.count !== "undefined") {
                //     setCount($count, result.count);
                // }
            // }
        // }).catch(e => {
        // console.warn(e);
        // setAttribute('hidden', true, $listLoading);
    // });
// }

export function getCartInfo() {
    requireConfigValues(['infoUrl'], configName);

    // console.log($listLoading)
    // $listLoading.attr('hidden', null);

    let request = fetch(getConfigValue('infoUrl', configName), {
        method: 'get',
    });

    showNotification(request);

    request.then(response => response.json())
        .then(result => {
            if (result.success) {
                if (typeof result.count !== "undefined") {
                    setCount($count, result.count);
                }

                setListContent($listResult, $listLoading, result.dropdownContent);
            }
        }).catch(e => {
        console.warn(e);
        // $listLoading.attr('hidden', 'hidden');
        // $listResult.attr('hidden', 'hidden');
    });
}

function getFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        for(let pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }
    });

    return formDataArray;
}

function setCount(elements, count) {
    if (count) {
        elements.map((item) => {
            removeAttribute('hidden', item);
            item.textContent = count;
        });
    } else {
        elements.map((item) => {
            setAttribute('hidden', true, item);
        });
    }
}

function setListContent($listResult, $listLoading, content) {
    if (content) {
        $listResult.innerHTML = content;
        setAttribute('hidden', true, $listLoading);
        removeAttribute('hidden', $listResult);
        initInScope($listResult);
    } else {
        setAttribute('hidden', true, $listLoading);
        setAttribute('hidden', true, $listResult);
    }
}

// Call the given function if it really is one
function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}