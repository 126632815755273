import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import "bootstrap/js/dist/collapse";
import { init } from "@elements/cookie-permissions/bootstrap-5";
init();

import 'bootstrap/js/dist/modal';

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as affixNav from './affix-nav';
affixNav.init();

import * as megaNav from './mega-nav';
megaNav.init();

import * as fadeIn from './fade-in';
fadeIn.init();

import * as cart from './cart';
cart.init();

import * as actionChanger from '@elements/action-changer';
actionChanger.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as formValidation from './form-validation';
formValidation.init();

import * as playVideo from './play-video';
playVideo.init();